.onoffswitch {
	position: relative;
	width: 33px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}
.onoffswitch-checkbox {
	display: none;
}
.onoffswitch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	height: 20px;
	padding: 0;
	line-height: 25px;
	border: 2px solid #e3e3e3;
	border-radius: 20px;
	background-color: #c72a34;
	transition: background-color 0.3s ease-in;
}
.onoffswitch-label:before {
	content: '';
	display: block;
	width: 20px;
	margin: 0px;
	background: #ffffff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 14px;
	border: 2px solid #e3e3e3;
	border-radius: 20px;
	transition: all 0.1s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
	background-color: #219f45;
}
.onoffswitch-checkbox:checked + .onoffswitch-label,
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
	border-color: #219f45;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
	right: 0px;
}

.onoffswitch-disabled {
	opacity: 0.5;
}
