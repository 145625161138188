@import '../Report.scss';

// Report View Switcher
.report-view-swicther-container {
	height: 72px;
	background-color: white;

	.report-header-container {
		display: flex;
		justify-content: center;
		margin-top: 20px;

		.report-header-text {
			font-size: 18px;
			font-weight: 600;
			text-align: center;
			margin-left: 10px;
		}
	}
}

// Order History Report Nav
.order-history-nav-container {
	display: flex;
	align-items: center;
	height: 64px;
	background-color: white;

	.nav-date-text {
		margin-left: 32px;
		margin-right: 49px;
		font-size: 18px;
		font-weight: 600;
		line-height: 16px;
		letter-spacing: -0.42px;
	}

	.nav-button {
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: -0.28px;
		color: $boston-blue;

		&.nav-button--blocked {
			color: $dusty-grey;
			cursor: default;
		}
	}

	.nav-icon-next {
		margin-left: 9px;
	}

	.nav-icon-prev {
		margin-right: 9px;
		transform: rotate(180deg);
	}

	.nav-spacer {
		margin-left: 19px;
		margin-right: 19px;
	}

	button {
		cursor: pointer;
		outline: none;
		border: none;
	}
}

// Order History List
.order-history-container {
	position: relative;
	display: flex;
	flex: 1;
	padding: 25px 15px 25px 25px;

	.order-history-list-container {
		position: absolute;
		top: 81px;
		left: 25px;
		right: 15px;
		bottom: 25px;
		overflow-y: auto;
	}

	.order-history-header-container {
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: space-between;

		.order-history-header-cell {
			display: flex;
			flex: 3;
			align-items: center;
			justify-content: center;
			width: 128px;
			height: 56px;

			span {
				font-size: 10px;
				color: $emperor-grey;
				letter-spacing: 0.46px;
			}

			&.order-history-header-cell-sm {
				flex: 1;
				width: 64px;
			}

			&.order-history-header-cell-medium {
				flex: 2;
				width: 96px;
			}
		}
	}

	.order-history-list-item-container {
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: space-between;

		.order-history-list-item-cell {
			display: flex;
			flex: 3;
			align-items: center;
			justify-content: center;
			width: 128px;
			height: 56px;
			border-color: $mercury-grey;
			border-width: 1px;
			border-style: solid;
			background-color: white;
			text-align: center;

			&.order-history-list-item-cell--warning {
				background-color: $fair-pink;
				color: $bright-red;
			}

			&.order-history-list-item-cell--cancelled {
				background-color: $wild-sand;
			}

			&.order-history-list-item-cell-sm {
				flex: 1;
				width: 64px;
			}

			&.order-history-list-item-cell-medium {
				flex: 2;
				width: 96px;
			}

			.order-history-list-item-price-text {
				font-weight: 600;
				line-height: 26px;
				text-align: center;
				letter-spacing: 0.46px;
			}
		}

		.order-history-id-button {
			display: flex;
			flex: 2;
			align-items: center;
			justify-content: center;
			width: 128px;
			background-color: $boston-blue;
			line-height: 56px;
			outline: none;

			font-size: 16px;
			text-align: center;
			color: white;
			letter-spacing: 0.46px;
		}

		.order-history-list-item-type-robot {
			margin-left: -10px;
		}
	}
}

// Report Summary
.report-summary-container {
	display: flex;
	height: 100px;
	background-color: white;
	align-items: center;

	.report-summary-print-button {
		display: flex;
		width: 155px;
		height: 50px;
		background-color: $boston-blue;
		justify-content: center;
		align-items: center;
		margin-left: 24px;
		border-radius: 5px;
		outline: none;

		.print-img {
			width: 24px;
			height: 24px;
			margin: 0px 13px 0px 0px;
		}

		.print-text {
			color: white;
			font-size: 12px;
			letter-spacing: 0.46px;
		}
	}

	.report-summary-blocks {
		display: flex;
		flex-direction: row;
		flex: 1;
		margin-left: 40px;
		margin-right: 24px;
		justify-content: space-around;

		.report-summary {
			display: flex;
			flex-direction: column;
			align-items: center;

			.summary-header {
				font-size: 10px;
				color: $emperor-grey;
				margin-bottom: 6px;
			}

			.summary-number {
				font-size: 26px;
				color: $emperor-grey;
				margin-right: 8px;
			}

			.summary-img {
				width: 16px;
				height: 16px;
				margin-bottom: 3px;
			}

			.summary-spacer {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
		}
	}
}
