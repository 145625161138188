.modal-background {
	position: relative;
	flex: 0 0 auto;
	display: flex;
	background: $white;
	margin: 0 0 1rem 0;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	overflow: hidden;
}
