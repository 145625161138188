.root {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #e0e0e0;
	height: 56px;
	cursor: pointer;
	user-select: none;

	> *:first-child {
		font-weight: 600;
	}
}

.active {
	background: #e0e0e0;
	padding: 0 25px;
	margin: -1px -25px 0;
	border-top: 1px solid #e0e0e0;
}
