@import 'src/assets/styles/variables';

.radio {
	flex: 1 1 auto;
	display: flex;
	justify-content: space-around;
	background: $light-gray;
	border-top: 1px solid #d9d9d9;
	overflow-y: auto;
}

.radio-content {
	width: 520px;
	max-width: calc(100% - 50px);
	margin: 25px;
}

.radio-title {
	margin: 35px 0 15px;
	font-size: 20px;
	text-align: center;
	letter-spacing: 1px;
}

.radio-subtitle {
	margin: 15px 0 30px;
	font-size: 14px;
	text-align: center;
	color: #4a4a4a;
}

.radio-group {
	margin-bottom: 15px;
	color: #767676;
	background: $white;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	& > .item-container {
		display: flex;
		flex-direction: column;
	}
}

.radio-header {
	display: flex;
	align-items: center;
	height: 56px;
	padding: 0 25px;
	font-size: 12px;
	font-weight: 600;
	justify-content: space-between;
	letter-spacing: 0.2px;
	text-transform: uppercase;
	background: #e0e0e0;
	border-bottom: 1px solid #d9d9d9;
	position: sticky;
	top: 0;
	z-index: 10;
}

.radio-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 56px;
	padding: 0 25px;
	font-size: 14px;
	color: $medium-gray;
	text-decoration: none;
	cursor: pointer;
	& > .sub-items {
		display: flex;
		flex-direction: column;
	}
	.item-resultcount {
		text-transform: uppercase;
		font-size: 12px;
	}
}

.radio-group .item-container + .item-container {
	border-top: 1px solid #f5f5f5;
}
.radio-item + .radio-item {
	border-top: 1px solid #f5f5f5;
}
.radio-item-active {
	font-weight: 600;
}
.radio-group .sub-items > .sub-item {
	min-height: 45px;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	& > * {
		display: flex;
		align-items: center;
		border-top: 1px solid #f5f5f5;
	}
	.item-category {
		margin-left: 40px;
		width: 100px;
		text-transform: uppercase;
		font-size: 12px;
		border-right: 1px solid #f5f5f5;
	}
	.item-title {
		flex: 1;
		padding-left: 8px;
	}
	.item-action {
		display: flex;
		padding-right: 25px;
	}
}
