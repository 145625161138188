@import 'src/assets/styles/variables';

.modal {
	overflow: hidden;
	outline: none;
	position: absolute;

	top: clamp(80px, 10vh, 160px);
	left: 50%;
	transform: translateX(-50%);

	max-width: 90%;
}

.overlay {
	position: fixed;
	inset: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba($black, 0.6);
	z-index: 1299; // MUI dialogs are 1300, so we need to be just below that to be able to layer dialogs on modals
}
