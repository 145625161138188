@import 'src/assets/styles/variables';

.root {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: stretch;
	background: $light-gray;
	border-top: 1px solid #e0e0e0;
}

.list {
	position: relative;
	flex: 0 0 325px;
	padding: 25px;
	color: $medium-gray;
	background: $white;
	overflow: auto;
	border-right: 1px solid #e0e0e0;
}

.content {
	display: flex;
	flex: 1;
	overflow-y: auto;
}

.contentWhite {
	background: $white;
}

.placeholder {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	justify-content: space-around;
	color: #868686;
	font-size: 14px;
	letter-spacing: 0.2px;
}

.user {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	font-size: 14px;
	border-bottom: 1px solid #e0e0e0;

	& > *:first-child {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-top: -1px;
		font-size: 16px;
		font-weight: 600;
	}

	& > *:last-child {
		flex: 0 0 auto;
		font-weight: normal;
		color: $medium-gray;
	}
}

.footer {
	position: absolute;
	left: 15px;
	bottom: 15px;
	font-size: 14px;
}
