$white: #fff;
$black: #000;
$medium-gray: #404040;
$dirty-gray: #c9c9c9;
$light-gray: #f2f2f2;
$dark-gray: #666;

$color-highlight: #a9ddff;
$color-success: #31814c;
$color-alert: #fff200;
$color-danger: #c72a33;
$color-action: #374de0;

$titlebar-height: 64px;
