@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables';

@font-face {
	font-family: 'MierB';
	src: url(../MierB-Book.otf) format('opentype');
}

@font-face {
	font-family: 'MierB';
	src: url(../MierB-Demi.otf) format('opentype');
	font-weight: 600;
}

@font-face {
	font-family: 'MierB';
	src: url(../MierB-Bold.otf) format('opentype');
	font-weight: 700;
}

html {
	font-family: MierB, serif;
	height: 100%;
	color: $black;
}

body {
	margin: 0;
	min-height: 100%;
	letter-spacing: 0.01em;
}

p {
	margin: 0;
}

h1 {
	font-size: 36px;
}

#root {
	height: 100vh;
	width: 100vw;
	background: $light-gray;
}

#overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	padding: 50px 25px;
	background: white;
	z-index: 99999;
}

.message {
	padding: 50px 25px;
	text-align: center;
}

.index-topbar {
	flex: 0 0 auto;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 64px;
	background: #fff;
	border-bottom: 1px solid #e0e0e0;
}

.index-topbar-center {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.index-topbar-logo {
	padding-top: 10px;
}

.index-spinner {
	position: fixed;
	left: calc(50vw - 32px);
	top: calc(40vh - 32px);
	width: 64px;
	height: 64px;
	transform: scale(0.4);
}

.index-spinner div {
	transform-origin: 32px 32px;
	animation: index-spinner 1.2s linear infinite;
}

.index-spinner div:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 3px;
	left: 29px;
	width: 5px;
	height: 14px;
	border-radius: 20%;
	background: #bfbfbf;
}

.index-spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
}

.index-spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
}

.index-spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
}

.index-spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
}

.index-spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
}

.index-spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
}

.index-spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
}

.index-spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
}

.index-spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
}

.index-spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
}

.index-spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
}

.index-spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
}

@keyframes index-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@layer utilities {
	.test-order {
		background: repeating-linear-gradient(
			135deg,
			$light-gray,
			$light-gray 10px,
			$white 10px,
			$white 20px
		);
	}
}

.welcome {
	background-image: url(../images/welcome-background-1920.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
