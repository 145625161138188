$modifier-value-height: 40px;
$item-height: 55px;

.inventory-search {
	.input-container {
		display: flex;
		flex: 1;
		position: relative;
		input {
			padding-left: 44px;
			font-size: 14px;
			height: 50px;
			border: none;
			flex: 1;
		}
		input::-webkit-input-placeholder {
		}
		.search-icon {
			display: flex;
			position: absolute;
			left: 15px;
			top: 0;
			bottom: 0;
			align-items: center;
		}
		.clear-search {
			color: white;
			display: flex;
			position: absolute;
			right: 15px;
			top: 0;
			bottom: 0;
			align-items: center;
			justify-content: center;
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 20px;
				width: 20px;
				background: rgba(0, 0, 0, 0.3);
				border-radius: 20px;
			}
		}
	}
}
.list-item-root {
}
.list-item-root.scrolling {
	.onoffswitch-label {
		transition: background-color 3s ease-in;
	}
}
.list-item-row {
	width: 520px;
	max-width: calc(100% - 50px);
	margin: 0 auto;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	flex-direction: column;
	background: white;
	height: 100%;
	// border-bottom: 1px solid #E2E2E2;
	position: relative;
}
.list-item-row::after {
	content: '';
	display: block;
	background: #e2e2e2;
	height: 1px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}
.list-item-showmore {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	text-transform: uppercase;
}
.inventory-content {
	flex: 1;
	display: flex;
	flex-direction: column;
}
.inventory-fixed {
	background: #f2f2f2;
	/*
    width: 520px;
    max-width: calc(100% - 50px);
    margin: 25px auto;
    display: flex;
    align-items: stretch;
    flex-direction: column;
     */
}
.inventory-controls {
	margin-bottom: 15px;
	color: #767676;
	background: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
}
.inventory-controls > * + * {
	border-top: 1px solid #e0e0e0;
}

.inventory-modifier {
	display: flex;
	.inventory-modifier-children {
		display: flex;
		flex: 1;
		flex-direction: column;
	}
	.inventory-modifier-label {
		width: 88 + 78px;
		font-size: 12px;
		color: #8c8c8c;
		text-transform: uppercase;
		line-height: 20px;
		padding-top: 10px;
		border-right: 1px solid #f2f2f2;
		text-align: right;
		padding-right: 16px;
	}
}
.inventory-modifier-value {
	display: flex;
	align-items: center;
	min-height: $modifier-value-height;
	padding-left: 16px;
	font-size: 14px;
	color: #404040;
	padding-right: 25px;
}
.inventory-modifier-value + .inventory-modifier-value {
	border-top: 1px solid #f2f2f2;
}
.item-list .header-button {
	height: 32px;
	background: #404040;
	font-size: 12px;
	color: white;
	border-radius: 8px;
	text-transform: uppercase;
	min-width: 238px;
}
.item-list .radio-item.modifier {
	align-items: flex-start;
}
.item-list .item-separator {
	width: 100%;
	height: 7px;
}

.item-list .list-item-inline-hilight {
	background: rgba(18, 255, 220, 0.34);
}
.inventory-content .breadcrumbs {
	position: absolute;
	z-index: 100;
	left: 0;
	right: 0;
	height: 42px;
	display: flex;
	align-items: center;
	background: #fbfbfb;
	border-bottom: 1px solid #e0e0e0;
	padding-left: 16px;
	padding-right: 16px;

	.button-back-to-top {
		justify-self: right;
		font-size: 14px;
		font-weight: bold;
		cursor: pointer;
		display: flex;
		align-items: center;
		& > svg {
			margin-left: 8px;
		}
	}
	.crumb-container {
		display: flex;
		& > div {
			display: flex;
			padding-right: 20px;
			margin-right: 10px;
			position: relative;
			font-size: 14px;

			& > .crumb-arrow {
				padding-bottom: 2px;
				display: flex;
				align-items: center;
				position: absolute;
				width: 7px;
				right: 0;
				bottom: 0;
				top: 0;
				svg {
					color: #bdbdbd;
				}
			}
			&:last-child > .crumb-arrow {
				display: none;
			}
		}
	}
}
