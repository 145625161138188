// Constant colors
$boston-blue: #3b78ba;
$bright-red: #b10217;
$fair-pink: #ffecee;
$mercury-grey: #e5e5e5;
$emperor-grey: #4f4f4f;
$dusty-grey: #9c9c9c;
$wild-sand: #f4f4f4;
$dove-gray: #737373;

.report-error-message {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $dove-gray;
	font-size: 18px;
	letter-spacing: 0.45px;
	line-height: 26px;
	text-align: center;
}

.report-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	height: calc(100vh - 64px);
}

.report-error-container {
	display: flex;
	flex: 1;
	justify-content: center;
}
