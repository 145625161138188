@import 'src/assets/styles/variables';

.root {
	position: relative;
}

.icon {
	position: absolute;
	top: 14px;
	left: 10px;
	padding: 10px;
	cursor: pointer;
	z-index: 99;
}
