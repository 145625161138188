@import 'src/assets/styles/variables';
@import 'src/assets/styles/error';

.pinInput {
	height: 100%;
	margin: 0 auto;
	padding-bottom: 3rem;
}

.input {
	padding: 1rem 0 3rem 0;
	height: 2rem;
	text-align: center;
}

.pinNumbers {
	margin-bottom: 0.5rem;
	font-weight: bold;
	font-size: large;
	display: flex;
	justify-content: center;
}

.number {
	&.transparent {
		color: transparent;
	}

	margin: 0 1rem;
	padding: 2px;
	border-bottom: 3px solid $black;
}

.numbersTable {
	font-size: larger;
	font-weight: bold;
	border-spacing: 0;

	tr {
		td {
			&:last-child {
				border-right: 0;
			}

			width: 7rem;
			height: 5rem;
			border-top: 0;
			border-right: 1px solid $dirty-gray;
			border-bottom: 1px solid $dirty-gray;
			border-left: 0;
			text-align: center;
			cursor: pointer;
		}

		&:last-child {
			td {
				border-bottom: 0;
			}
		}
	}
}
