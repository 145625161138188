@import 'src/assets/styles/variables';
@import 'src/assets/styles/modal';
@import 'src/assets/styles/error';

.changeStatusModal {
	@extend .modal-background;
	flex-flow: nowrap;
}

.leftMenu {
	width: 24%;
	padding: 6rem 1.5rem 1.5rem 1.5rem;
	border-right: 1px solid $dirty-gray;
}

.acceptingButton {
	.status {
		padding-top: 0.5rem;
		font-size: xx-large;
		color: $color-success;
	}

	&.active {
		box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
		cursor: default;
		color: $white;
		background-color: $color-success;

		.status {
			color: $white;
		}
	}

	width: 10rem;
	padding: 1rem;
	border-radius: 1rem;
	color: $black;
	background-color: $light-gray;
	margin-bottom: 1rem;
	cursor: pointer;
}

.pausedButton {
	@extend .acceptingButton;

	.status {
		color: $color-danger;
	}

	&.active {
		background-color: $color-danger;
		border: 2px solid $color-success;
	}
}

.rightBlock {
	width: calc(76% - 8rem);
	margin: 1.5rem 2.5rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.header {
	font-size: xx-large;
	font-weight: bold;
	margin-bottom: 1.5rem;
}

.content {
	font-size: large;
	height: 100%;
}

.footer {
	display: flex;
	justify-content: flex-end;
}

.closeButton,
.updateButton {
	cursor: pointer;
	font-size: large;
	padding: 1.5rem 1.5rem;
	letter-spacing: 0.25rem;
}

.closeButton {
	&:after {
		content: 'CLOSE';
	}

	margin-right: 2rem;
}

.updateButton {
	&:after {
		content: 'UPDATE';
	}

	background-color: $color-action;
	color: $white;
}

.disabled {
	opacity: 0.5;
	cursor: default;
}

.timeSelector {
	display: flex;
	flex-flow: row;
	overflow-x: auto;
	font-size: x-large;
	padding: 2rem 0;
	margin-bottom: 1.5rem;
}

.timeOption {
	&.active {
		cursor: default;
		border: 0.5rem solid $color-action;
	}

	border: 0.5rem solid $white;
	cursor: pointer;
	box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.2);
	white-space: nowrap;
	width: max-content;
	display: block;
	padding: 1.5rem 3rem;
	margin-right: 1rem;
}

.acceptingOrders {
	&.paused {
		background-color: $color-danger;
	}

	&.working {
		background-color: $color-success;
	}

	font-size: small;
	border-radius: 1rem;
	padding: 0.5rem 3rem;
	margin-right: 1rem;
	color: $white;
	cursor: pointer;
}

.modal {
	top: 50%;
	transform: translate(-50%, -50%);
}
